<div class="encabezado">
  <div class="">
    <div class="">
      <div>
        <h5 class="titulo1">{{ "pages.titles.clients" | translate }}</h5>
      </div>
    </div>
    <!-- end card body -->
  </div>
  <div class="row">
    <div class="col-8">
      <app-filter (filterChanged)="filterChanged($event)"></app-filter>
    </div>
    <div class="col-4">
      <div class="btn-izq">
        <button class="btn btn-hover" type="button" (click)="toggleForm()">
          {{ "buttons.add+" | translate }} <i class="icon-plus"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="tabla">
    <table mat-table [dataSource]="dataSource" matSort class="full-width-table" aria-label="Clients">

      <!-- Tax id Column -->
      <ng-container matColumnDef="tax_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
          {{ "forms.inputs.tax_id" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ row.tax_id }}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
          {{ "forms.inputs.type" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{
          row.type === 'individual'
          ? 'Particular'
          : (row.type === 'self-employed'
          ? 'Autónomo'
          : (row.type === 'organization'
          ? 'Empresa'
          : row.type))
          }}
        </td>
      </ng-container>


      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
          {{ "forms.inputs.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.type === 'organization' ? row.name : (row.name + (row.lastname ? ' ' + row.lastname : '')) }}
        </td>
      </ng-container>



      <!-- email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
          {{ "forms.inputs.email" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container>

      <!-- phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
          {{ "forms.inputs.phone" | translate }}
        </th>
        <td mat-cell *matCellDef="let row"><span *ngIf="row.phone">{{ row.phone }}</span></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla">
          {{ "tables.columns.actions" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <i class="icon-pencil text-success icon-actions" (click)="editClient(row.reference)"></i>
          <i *ngIf="!row.has_order && !row.is_assignee" class="icon-trash text-danger icon-actions"
            (click)="deleteClient(row.reference)"></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="paginator.length" [pageSize]="paginator.pageSize"
      [pageSizeOptions]="paginator.pageSizeOptions" (page)="onPageChange($event)">
    </mat-paginator>

  </div>
</div>

<div class="formulario-derecha-container" [ngClass]="{ 'mostrar-formulario': showForm }">
  <app-form-add-client (backButtonClick)="cerrarFormulario()" (addClient)="refreshClients()">
  </app-form-add-client>
</div>

<div class="formulario-derecha-container" [ngClass]="{ 'mostrar-formulario': showFormEdit }">
  <app-form-edit-client *ngIf="showFormEdit" [clientData]="client_reference" [tab]="tab"
    (backButtonClickEdit)="cerrarFormularioEdit()" (clientUpdated)="onClientUpdated()" [selectTypes]="selectTypes"
    (emitOpenAssigneeForm)="openAssigneeForm($event)" (emitCloseClientForm)="closeClientForm()"
    (emitCloseAssigneeForm)="closeAssigneeForm()" [document_buttons_chico]="document_buttons_chico"
    (emitOpenClientForm)="openClientForm()"></app-form-edit-client>
</div>

<div class="formulario-sub-derecha-container" [ngClass]="{'mostrar-formulario': showAssigneeForm }">
  <app-form-show-assignee (backButtonClick)="cerrarAssigneeFormulario()" [assignee]="assignee"
    (addClient)="refreshClients()">
  </app-form-show-assignee>
</div>


<div class="overlay" *ngIf="showForm || showFormEdit " (click)="cerrarFormulario()"></div>