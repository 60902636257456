<div class="encabezado">
  <div>
    <div>
      <div>
        <h5 class="titulo1">{{ "pages.titles." + title | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="">
    <div class="">
      <div class="row">
        <div class="col-8">
          <app-filter [ladata]="ladata" (filterChanged)="filterChanged($event)"></app-filter>
        </div>
        <div class="col-4" *ngIf="accountType == 'broker'">
          <div class="btn-izq">
            <button class="btn btn-hover" type="button" (click)="toggleForm()">
              {{ "buttons.add+" | translate }} <i class="icon-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="showAlertFilter">
      <div class="col-12">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <div *ngIf="showCountFilter > 0"> <span>{{ "filter.part1" | translate }} <strong>{{showCountFilter}} {{
                "filter.part2_" + typeInvoices | translate }}</strong></span> {{ "filter.part3" | translate }} <span
              *ngIf="showAlertFilterAll">{{
              "filter.part4" | translate }} <strong> <a routerLink="/orders/all"> {{
                  "filter.part5" | translate }}</a> </strong></span></div>



          <div *ngIf="showCountFilter == 0"><span> <strong>{{ "filter.part6" | translate }} {{"filter.part2_" +
                typeInvoices
                |
                translate }}</strong>{{ "filter.part3" | translate }} </span> <span *ngIf="showAlertFilterAll"> {{
              "filter.part4" | translate }} <strong> <a routerLink="/orders/all"> {{
                  "filter.part5" | translate }}</a> </strong></span>
          </div>

        </div>
      </div>
    </div>


  </div>

  <div class="formulario-derecha-container" [ngClass]="{ 'mostrar-formulario': showForm }">
    <app-form-add-invoices *ngIf="showForm" [invoiceData]="invoiceData" [tab]="tab" (backButtonClick)="cerrarFormulario()"
    (addOrder)="refrescar()" [document_buttons_chico]="document_buttons_chico" [ladata]="ladata"></app-form-add-invoices>
  </div>

  <div class="overlay" *ngIf="showForm" (click)="cerrarFormulario()"></div>
</div>
