<div class="vertical-menu">
  <!-- LOGO -->
  <div class="navbar-brand-box">
    <a href="#" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/logo-abbr-dark.png" alt="" height="22" />
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-abbr-dark.png" alt="" height="22" />
      </span>
    </a>

    <a href="#" class="logo logo-light">
      <span class="logo-lg">
        <img src="assets/images/logo-abbr-clear.png" alt="" height="22" />
      </span>
      <span class="logo-sm">
        <img src="assets/images/logo-abbr-clear.png" alt="" height="22" />
      </span>
    </a>
  </div>

  <div *ngIf="type == 'supplier'">
    <div data-simplebar class="sidebar-menu-scroll">
      <div class="name-bussines">
        <div class="d-flex align-items-center">
          <div class="circle">{{ name.charAt(0) }}</div>
          <div class="d-flex flex-column">
            <span>{{ name }}</span>
            <span class="type">{{ account_name }}</span>
          </div>
        </div>
        <a *ngIf="role == 'Admin'"
        class="has-submenu"
        (click)="toggleSubmenuConf($event)"
        (mouseenter)="cancelCloseTimeoutConf()"
        (mouseleave)="startCloseTimeoutConf()"  ><i class="icon-cog"></i>
        </a>
      </div>
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li class="menu-title" data-key="t-menu">
            {{ "menu.menu" | translate }}
          </li>
          <li class="menu-item-has-children" (click)="toggleSubmenuOrders()">
            <a class="d-flex justify-content-between">
              <div>
                <i class="icon-clipboard-check"></i>
                <span class="menu-item" data-key="t-orders">{{
                  "menu.orders" | translate
                }}</span>
              </div>

              <i
                class=""
                [ngClass]="{
                  'icon-chevron-up': submenuOrders,
                  'icon-chevron-down': !submenuOrders
                }"
              ></i>
            </a>
            <ul *ngIf="submenuOrders" class="submenu">
              <li>
                <a routerLink="/orders/contracts">
                  <div>
                    <i class="icon-register"></i>
                    <span class="menu-item" data-key="t-contracts">{{
                      "menu.contracts" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders/after-sales">
                  <div>
                    <i class="icon-history"></i>
                    <span class="menu-item" data-key="t-after-sales">{{
                      "menu.after-sales" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders/delivered">
                  <div>
                    <i class="icon-car"></i>
                    <span class="menu-item" data-key="t-delivered">{{
                      "menu.delivered" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders/cancelled">
                  <div>
                    <i class="icon-cross-circle"></i>
                    <span class="menu-item" data-key="t-cancelled">{{
                      "menu.cancelled" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders">
                  <div>
                    <i class="icon-box"></i>
                    <span class="menu-item" data-key="t-all">{{
                      "menu.all" | translate
                    }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="menu-item-has-children" >
            <a class="d-flex justify-content-between"  (click)="toggleSubmenuInvoices()">
              <div >
                <i class="icon-clipboard-check"></i>
                <span class="menu-item" data-key="t-invoices">{{
                  "menu.invoices" | translate
                }}</span>
              </div>

              <i
                class=""
                [ngClass]="{
                  'icon-chevron-up': submenuInvoices,
                  'icon-chevron-down': !submenuInvoices
                }"
              ></i>
            </a>
             <ul *ngIf="submenuInvoices" class="submenu">
              <li>
                <a routerLink="/invoices/pending">
                  <div>
                    <i class="icon-register"></i>
                    <span class="menu-item" data-key="t-pending">{{
                      "menu.pending" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/invoices/paid">
                  <div>
                    <i class="icon-history"></i>
                    <span class="menu-item" data-key="t-paid">{{
                      "menu.paid" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/invoices/cancelled">
                  <div>
                    <i class="icon-cross-circle"></i>
                    <span class="menu-item" data-key="t-cancelled">{{
                      "menu.cancelled" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/invoices">
                  <div>
                    <i class="icon-box"></i>
                    <span class="menu-item" data-key="t-all">{{
                      "menu.all" | translate
                    }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
  <div *ngIf="type == 'broker'">
    <div data-simplebar class="sidebar-menu-scroll">
      <div class="name-bussines">
        <div class="d-flex align-items-center">
          <div class="circle">{{ name.charAt(0) }}</div>
          <div class="d-flex flex-column">
            <span>{{ name }}</span>
            <span class="type">{{ account_name }}</span>
          </div>
        </div>

        <a *ngIf="role == 'Admin'"
        class="has-submenu"
        (click)="toggleSubmenuConf($event)"
        (mouseenter)="cancelCloseTimeoutConf()"
        (mouseleave)="startCloseTimeoutConf()"  ><i class="icon-cog"></i>
        </a>
      </div>

      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li
            class="has-submenu"
            (click)="toggleSubmenu($event)"
            (mouseenter)="cancelCloseTimeout()"
            (mouseleave)="startCloseTimeout()"
          >
            <a class="resaltar">
              <div class="d-flex">
                <i class="icon-plus"></i>
                <span class="menu-item" data-key="t-submenu">
                  {{ "menu.create" | translate }}
                </span>
              </div>
              <i class="icon-chevron-right"></i>
            </a>
          </li>
          <li class="menu-title" data-key="t-menu">
            {{ "menu.menu" | translate }}
          </li>
          <li class="menu-item-has-children" >
            <a class="d-flex justify-content-between"  (click)="toggleSubmenuOrders()">
              <div >
                <i class="icon-clipboard-check"></i>
                <span class="menu-item" data-key="t-orders">{{
                  "menu.orders" | translate
                }}</span>
              </div>

              <i
                class=""
                [ngClass]="{
                  'icon-chevron-up': submenuOrders,
                  'icon-chevron-down': !submenuOrders
                }"
              ></i>
            </a>
            <ul *ngIf="submenuOrders" class="submenu">
              <li>
                <a routerLink="/orders/drafts">
                  <div>
                    <i class="icon-plus"></i>
                    <span class="menu-item" data-key="t-drafts">{{
                      "menu.drafts" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders/contracts">
                  <div>
                    <i class="icon-register"></i>
                    <span class="menu-item" data-key="t-contracts">{{
                      "menu.contracts" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders/after-sales">
                  <div>
                    <i class="icon-history"></i>
                    <span class="menu-item" data-key="t-after-sales">{{
                      "menu.after-sales" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders/delivered">
                  <div>
                    <i class="icon-car"></i>
                    <span class="menu-item" data-key="t-delivered">{{
                      "menu.delivered" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders/cancelled">
                  <div>
                    <i class="icon-cross-circle"></i>
                    <span class="menu-item" data-key="t-cancelled">{{
                      "menu.cancelled" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/orders">
                  <div>
                    <i class="icon-box"></i>
                    <span class="menu-item" data-key="t-all">{{
                      "menu.all" | translate
                    }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a routerLink="/clients" (click)="closeSubmenuOrders()">
              <div>
                <i class="icon-user"></i>
                <span class="menu-item" data-key="t-calendar">{{
                  "menu.clients" | translate
                }}</span>
              </div>
            </a>
          </li>
          <!-- <li class="menu-item-has-children" >
            <a class="d-flex justify-content-between"  (click)="toggleSubmenuInvoices()">
              <div >
                <i class="icon-clipboard-check"></i>
                <span class="menu-item" data-key="t-invoices">{{
                  "menu.invoices" | translate
                }}</span>
              </div>

              <i
                class=""
                [ngClass]="{
                  'icon-chevron-up': submenuInvoices,
                  'icon-chevron-down': !submenuInvoices
                }"
              ></i>
            </a>
            <ul *ngIf="submenuInvoices" class="submenu">
              <li>
                <a routerLink="/invoices/drafts">
                  <div>
                    <i class="icon-plus"></i>
                    <span class="menu-item" data-key="t-drafts">{{
                      "menu.drafts" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/invoices/pending">
                  <div>
                    <i class="icon-register"></i>
                    <span class="menu-item" data-key="t-pending">{{
                      "menu.pending" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/invoices/paid">
                  <div>
                    <i class="icon-history"></i>
                    <span class="menu-item" data-key="t-paid">{{
                      "menu.paid" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/invoices/cancelled">
                  <div>
                    <i class="icon-cross-circle"></i>
                    <span class="menu-item" data-key="t-cancelled">{{
                      "menu.cancelled" | translate
                    }}</span>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/invoices">
                  <div>
                    <i class="icon-box"></i>
                    <span class="menu-item" data-key="t-all">{{
                      "menu.all" | translate
                    }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>

  <div
  class="nav-second-level grid-2x2"
  [ngClass]="{ collapse: submenuOpen }"
  (mouseenter)="cancelCloseTimeout()"
  (mouseleave)="startCloseTimeout()"
>
  <div
    class="row"
    (mouseenter)="cancelCloseTimeout()"
    (mouseleave)="startCloseTimeout()"
  >
    <div class="col-6 submenuitem">
      <a class="subitem-menu" [href]="'/orders/contracts/add'">
        <i class="icon-clipboard-pencil"></i>
        <a>
          <span class="menu-item" data-key="t-calendar">{{
            "menu.order" | translate
          }}</span>
        </a>
      </a>
    </div>
    <div class="col-6 submenuitem">
      <a class="subitem-menu" [href]="'/clients/add'">

        <i class="icon-user-plus"></i>
        <a>
          <span class="menu-item" data-key="t-calendar">{{
            "menu.client" | translate
          }}</span>
        </a>
      </a>
    </div>
  </div>
</div>



  <div
    class="nav-settings grid-2x2"
    [ngClass]="{ collapse: submenuOpenConf }"
    (mouseenter)="cancelCloseTimeoutConf()"
    (mouseleave)="startCloseTimeoutConf()"
  >
    <div
      class="row"
      (mouseenter)="cancelCloseTimeoutConf()"
      (mouseleave)="startCloseTimeoutConf()"
    >
      <div class="col-12 submenuitem">
        <div class="subitem-menu" routerLink="/settings">
          <i class="icon-cog"></i>
          <a>
            <span class="menu-item" data-key="t-settings">{{
              "menu.settings" | translate
            }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Left Sidebar End -->
</div>
