<div class="document-buttons" [ngClass]="{ 'document-buttons-chico': document_buttons_chico }">
  <div *ngIf="!draft" id="btn-general" class="document-button" [ngClass]="activeForm == 'general' ? 'selected' : ''"
    (click)="emitchangeForm('general')">
    <button type="submit" class="btn-documents">
      <i class="icon-clipboard-empty"></i>
      {{ "forms.buttons.new" | translate }}
      <span *ngIf="updatesTabs.includes('general')" class="violet-dot"></span>
    </button>
  </div>
  <div *ngIf="draft">
    <div class="document-button" [ngClass]="activeForm == 'general' ? 'selected' : ''"
      (click)="emitchangeForm('general')">
      <button type="submit" class="btn-documents">
        <i class="icon-clipboard-empty"></i>
        {{ "forms.buttons.general" | translate }}
        <span *ngIf="updatesTabs.includes('general')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-order" class="document-button" [ngClass]="activeForm == 'order' ? 'selected' : ''"
      (click)="emitchangeForm('order')">
      <button type="submit" class="btn-documents">
        <i class="icon-car"></i>
        {{ "forms.buttons.order" | translate }}
        <span *ngIf="updatesTabs.includes('order')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-notes" class="document-button" [ngClass]="activeForm == 'notes' ? 'selected' : ''"
    (click)="emitchangeForm('notes')">
    <button type="submit" class="btn-documents">
      <i class="icon-register"></i>
      {{ "forms.buttons.notes" | translate }}
      <span *ngIf="updatesTabs.includes('notes')" class="violet-dot"></span>
    </button>
  </div>
    <div id="btn-timeline" class="document-button" [ngClass]="activeForm == 'timeline' ? 'selected' : ''"
      (click)="emitchangeForm('timeline')">
      <button type="submit" class="btn-documents">
        <i class="icon-align-left"></i>
        {{ "forms.buttons.timeline" | translate }}
        <span *ngIf="updatesTabs.includes('timeline')" class="violet-dot"></span>
      </button>
    </div>
  </div>
</div>
