<div class="d-flex">
  <div class="document-buttons">
    <div id="btn-general" class="document-button general selected">
      <button type="submit" class="btn-documents">
        <i class="icon-user"></i>
        {{ "forms.buttons.new" | translate }}
      </button>
    </div>
  </div>

  <div class="card w-100 form-right">

    <div class="back">
      <div class="div-title">
        <h2 class="">
          {{ "forms.names.addClient" | translate }}
        </h2>
      </div>
      <i class="icon-cross2" (click)="emitBackButtonClick()"></i>
    </div>

    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-section mb-3 pb-3">

          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-3">
                <label for="type" class="form-label">{{ "forms.inputs.type" | translate }}
                  <span class="required">*</span>
                </label>
                <div class="position-relative">
                  <select id="type" class="form-control" formControlName="type">
                    <option value="" disabled selected>{{ "forms.inputs.type" | translate }}</option>
                    <option value="organization">{{ "forms.inputs.company" | translate }}</option>
                    <option value="individual">{{ "forms.inputs.individual" | translate }}</option>
                    <option value="self-employed">{{ "forms.inputs.employee" | translate }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row linea"></div>

          <!-- Referencia -->
          <div class="mb-3">
            <div class="row">
              <div class="col-6" *ngIf="form.get('type')?.value !== 'organization'">
                <div class="mb-3">
                  <label for="vendor" class="form-label">{{ "forms.inputs.name" | translate }}
                    <span class="required">*</span>
                  </label>
                  <input id="vendor" class="form-control" formControlName="name"
                    placeholder="  {{ 'forms.inputs.name' | translate }}" />
                </div>
              </div>
              <div class="col-6" *ngIf="form.get('type')?.value !== 'organization'">
                <div class="mb-3">
                  <label for="supplier" class="form-label">{{ "forms.inputs.lastname" | translate }}</label>
                  <input id="lastname" class="form-control" formControlName="lastname"
                    placeholder=' {{ "forms.inputs.lastname" | translate }}' />
                </div>
              </div>
            </div>

            <div class="row ">
              <div class="col-12" *ngIf="form.get('type')?.value === 'organization'">
                <div class="mb-3">
                  <label for="vendor" class="form-label">{{ "forms.inputs.name" | translate }}
                    <span class="required">*</span>
                  </label>
                  <input id="vendor" class="form-control full-width" formControlName="name"
                    placeholder=" {{ 'forms.inputs.name' | translate }}" />
                </div>
              </div>
            </div>

          </div>

          <div class="row linea"></div>

          <div class="row mb-2" *ngIf="form.get('type')?.value === 'organization'">
            <div class="col-12">
              <div class="mb-3">
                <label for="supplier" class="form-label">{{ "forms.inputs.tax_id" | translate }}
                  <span class="required">*</span></label>
                <input id="tax_id" class="form-control" formControlName="tax_id"
                  placeholder='{{ "forms.inputs.tax_id" | translate }}' />
              </div>
            </div>
          </div>

          <div class="row mb-2" *ngIf="form.get('type')?.value !== 'organization'">

            <div class="col-6">
              <div class="mb-3">
                <label for="birth_date" class="form-label">{{ "forms.inputs.birth_date" | translate }}</label>
                <mat-form-field appearance="fill">
                  <input matInput [matDatepicker]="birthDatepicker" formControlName="birth_date"
                    placeholder="{{ 'forms.inputs.birth_date' | translate }}" (dateChange)="onDateChange($event)" [min]="minDate" [max]="maxDate">

                  <mat-datepicker-toggle matSuffix [for]="birthDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #birthDatepicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-3">
                <label for="supplier" class="form-label">{{ "forms.inputs.tax_id" | translate }}
                  <span class="required">*</span></label>
                <input id="tax_id" class="form-control" formControlName="tax_id"
                  placeholder='{{ "forms.inputs.tax_id" | translate }}' />
              </div>
            </div>
          </div>

          <div *ngIf="form.get('type')?.value === 'organization'">
            <div class="row linea"></div>
            <div class="row mb-2">
              <div class="col-12">
                <div class="mb-3">

                  <label for="client" class="form-label">{{ "forms.inputs.associateClient" | translate }}</label>

                  <ng-select [items]="filteredClients" bindLabel="displayName" bindValue="reference"
                    formControlName="assignee_id" [searchable]="true"
                    placeholder="{{ 'forms.inputs.associateClient' | translate }}" [clearable]="true"
                    [class.has-value]="form.get('assignee_id')?.value" class="select-client-associated"
                    [typeahead]="search$" (search)="onSearch($event)" [virtualScroll]="true">
                    <!-- <ng-template ng-option-tmp let-item="item">
                      <span>{{ item.name }} {{ item.lastname ? item.lastname : '' }}</span>
                    </ng-template> -->
                  </ng-select>

                </div>
              </div>
            </div>
          </div>

          <div class="row linea"></div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-3">
                <label for="supplier" class="form-label">{{ "forms.inputs.email" | translate }}
                  <span class="required">*</span></label>
                <input id="email" class="form-control" formControlName="email"
                  placeholder=' {{ "forms.inputs.email" | translate }}' />
              </div>
              <div *ngIf="isFieldInvalid('email')">
                <small *ngIf="getFormError('email', 'required')" class="error_validation">El correo es obligatorio.</small>
                <small *ngIf="getFormError('email', 'email')" class="error_validation">El formato del correo es inválido.</small>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4">
              <div class="mb-3">
                <label for="phone_prefix" class="form-label">{{ "forms.inputs.phone_prefix" | translate }}
                  <span class="required">*</span>
                </label>
                <app-select-country-prefix (changePrefix)="changePrefix($event)"
                  [selectedPrefix]="form.get('phonePrefix')?.value"></app-select-country-prefix>
              </div>
            </div>

            <div class="col-8">
              <div class="mb-3">
                <label for="phone" class="form-label">{{ "forms.inputs.phone" | translate }}
                  <span class="required">*</span>
                </label>
                <input id="phone" class="form-control phone-number" formControlName="phoneNumber" type="tel"
                  placeholder="{{ 'forms.inputs.phone' | translate }}" (input)="onInput($event)" />
              </div>
              <div *ngIf="form.hasError('invalidPhoneNumber') && form.get('phoneNumber')?.touched">
                <small  class="error_validation" >Si el prefijo es +34, el número debe tener 9 dígitos.</small>
              </div>

              <div *ngIf="isFieldInvalid('phoneNumber')">
                <small class="error_validation">El teléfono debe tener 9 dígitos, o si empieza con +34, debe tener 9 dígitos adicionales.</small>
              </div>
            </div>
          </div>

          <div class="row linea"></div>

          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="billing_address" class="form-label">{{
                  "forms.inputs.billing_address" | translate
                  }}</label>
                <input id="billing_address" class="form-control" formControlName="billing_address" placeholder="{{
                                    'forms.inputs.billing_address' | translate
                                  }}" />
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="billing_state" class="form-label">{{
                  "forms.inputs.billing_state" | translate
                  }}</label>
                <input id="billing_state" class="form-control" formControlName="billing_state" placeholder="{{
                                                    'forms.inputs.billing_state' | translate
                                                  }}" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="name" class="form-label">{{
                  "forms.inputs.billing_postal_code" | translate
                  }}</label>
                <input id="name" class="form-control" formControlName="billing_postal_code" placeholder="{{
                                    'forms.inputs.billing_postal_code' | translate
                                  }}" />
              </div>
              <div *ngIf="isFieldInvalid('billing_postal_code')">
                <small class="error_validation" >El codigo postal debe tener 4 o 5 números</small>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="lastname" class="form-label">{{
                  "forms.inputs.billing_city" | translate
                  }}</label>
                <input id="lastname" class="form-control" formControlName="billing_city" placeholder="{{
                                    'forms.inputs.billing_city' | translate
                                  }}" />
              </div>
            </div>
          </div>

        </div>

        <div class="button-create-container">
          <button type="submit" class="btn btn-primary btn-primary-form create-button" >
            {{ "buttons.create_client" | translate }}
          </button>
        </div>
      </form>

    </div>

  </div>
  <!-- end card body -->
</div>
