import { Title } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, Location } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatPaginatorModule, MatPaginator, PageEvent } from '@angular/material/paginator';

import { Orders } from '../../core/interfaces/orders';
import { AlertService } from '../../core/services/alert/alert.service';
import { OrdersService } from '../../core/services/orders/orders.service';
import { AccountsService } from '../../core/services/accounts/accounts.service';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';

import { FilterComponent } from './filter/filter.component';
import { FormAddInvoicesComponent } from './form-add-invoices/form-add-invoices.component';

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any; // ajusta según la estructura real de root_account si es necesario
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
  // Otros campos posibles como error, message, etc.
}
@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    MatSortModule,
    MatTableModule,
    NgSelectModule,
    TranslateModule,
    FilterComponent,
    MatPaginatorModule,
    ReactiveFormsModule,
    FormAddInvoicesComponent
  ],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.css'
})
export class InvoicesComponent {
  private route = inject(ActivatedRoute);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  showForm = false;
  showClear = false;

  pageNumber: number = 1;
  pageSize: number = 100;

  order_reference?: any;
  invoiceData?: any;

  typeInvoices: string = '';

  dataSource = new MatTableDataSource<Orders>();
  title = 'orders';

  showAlertFilter = false;
  showCountFilter = 0;
  showAlertFilterAll = false;
  searchParams: any = {};
  accountType: string | null = null;

  ladata: any = [];
  cargaladata: any = [];

  document_buttons_chico = false;
  tab = "general";

  displayedColumnsBroker: string[] = [
    'stage',
    'actions',
  ];

  displayedColumnsSupplier: string[] = [
    'stage',
    'actions',
  ];

  displayedColumns: string[] = [];

  type: any = 'broker';
  autoFetch: any;

  // Mapa para relacionar rutas con sus títulos y tipos de orden
  routesMap: { [key: string]: string } = {
    'invoices/all': '',
    'invoices/paid': 'paid',
    'invoices/drafts': 'drafts',
    'invoices/pending': 'pending',
    'invoices/delivered': 'delivered',
    'invoices/cancelled': 'cancelled',
    'invoices': '',
  };

  constructor(
    private location: Location,
    private titleService: Title,
    private alertService: AlertService,
    private translate: TranslateService,
    private orderService: OrdersService,
    private accountService: AccountsService,
    private localStorageService: LocalStorageService
  ) {
    this.type = this.localStorageService.getItem('account_type');
    this.displayedColumns =
      this.type === 'broker'
        ? this.displayedColumnsBroker
        : this.displayedColumnsSupplier;
  }

  ngOnInit() {
   // this.alertService.loading('swal_messages.please_wait');
    this.detectReference(),
    this.fetchAllData();
    this.startAutoFetch();

  }

  startAutoFetch(): void {
    if (this.autoFetch) {
      clearInterval(this.autoFetch);
    }
    // this.autoFetch = setInterval(() => {
    //   this.refreshInvoiceData();
    // }, 40000);
  }

  private fetchAllData() {
    Promise.all([
     // this.fetchData(),
      this.fetchData2()
    ]).then(() => {
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  agentData: AccountData[] = [];
  accountData: AccountData[] = [];
  organizationData: AccountData[] = [];
  dealershipData: AccountData[] = [];

  expandedRow: any = null;

  toggleRow(row: any): void {
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      // this.alertService.loading('swal_messages.please_wait');
      this.orderService
        .getData(
          this.typeInvoices,
          this.pageNumber,
          this.pageSize,
          this.searchParams
        )
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.cargaladata.flag = true;
            this.dataSource.sort = this.sort;
            this.pageNumber = data.meta.current_page;
            if (!this.localStorageService.getItem('per_page')) {
              this.localStorageService.setItem('per_page', data.meta.per_page);
              this.pageSize = data.meta.per_page;
            } else {
              this.pageSize = Number(this.localStorageService.getItem('per_page'));
            }
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.meta.total;
            this.alertService.close();
            setTimeout(() => {
              resolve();
            }, 500); // Espera de 1 segundo antes de resolver la promesa
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  fetchData2(): Promise<void> {

    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve(); // Termina la ejecución y resuelve la promesa si no hay datos
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.organizationData = data.filter(
            (item) => item.type === 'organization' || item.type === 'broker'
          );
          this.cargaladata.dealershipData = data.filter(
            (item) => item.type === 'dealership'
          );
          resolve();
        },
        error: (error) => {
          reject(error); // Rechaza la promesa en caso de error
        },
      });
    });
  }

  getInvoice(reference: string): void {
    this.alertService.loading('swal_messages.please_wait');
    const nuevaURL = this.location.path();

    var aux = this.typeInvoices == '' ? this.typeInvoices : this.typeInvoices + '/';
    if (!nuevaURL.includes('invoices')) {
      const nuevaURL = this.location.path() + '/invoices/' + aux + reference;
      this.location.go(nuevaURL);
    } else {
      const index = nuevaURL.indexOf('/');
      this.location.go(nuevaURL.substring(0, index) + '/invoices/' + aux + reference);
    }

    this.order_reference = reference;
    this.orderService.searchOrder(this.order_reference).subscribe({
      next: (data) => {
        this.invoiceData = data.data;
        let title = data.data.client?.name ? data.data.client?.name + " " + (data.data.client?.lastname ? data.data.client?.lastname : '') : data.data.broker.reference
        this.titleService.setTitle(this.translate.instant("pages.titles." + this.title) + " " + title + "- DBM");
        this.showForm = true;
        this.alertService.close();
      },
      error: (error) => {
        this.alertService.error('swal_messages.order_not_found');
      },
    });
  }

  detectReference() {
    this.pageSize = Number(this.localStorageService.getItem('per_page'));
    // Capturar el parámetro 'tab'
    this.route.queryParams.subscribe(params => {
      if (params['tab']) {
        this.tab = params['tab'];
      }
    });

    // Obtener el tipo de cuenta del localStorage
    this.accountType = localStorage.getItem('account_type');

    // Obtener la URL actual
    const nuevaURL = this.location.path();

    // Iterar sobre el mapa de rutas y actualizar typeInvoices y title si coincide
    for (const route in this.routesMap) {
      if (nuevaURL.includes(route)) {
        this.typeInvoices = this.routesMap[route];
        const nuevaRuta = `/invoices/${this.typeInvoices}`;
        this.location.go(nuevaRuta);
        this.title = this.typeInvoices == '' ? 'all' : this.typeInvoices;
        this.showAlertFilterAll = this.typeInvoices == '' ? false : true;
        break; // Salimos del bucle una vez encontrado el match
      }
    }

    // Obtener la referencia de la orden
    let paramReference = this.route.snapshot.paramMap.get('reference');
    if (paramReference) {
      this.order_reference = paramReference;
      const updatedURL = `/invoices/${this.typeInvoices}/${paramReference}`;
      this.location.go(updatedURL);
      this.getInvoice(paramReference);
    }

  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  cerrarFormulario() {
    this.titleService.setTitle(this.translate.instant("pages.titles." + this.typeInvoices) + " - DBM");

    console.log("🚀 ~ InvoicesComponent ~ cerrarFormulario ~  this.typeInvoices:",  this.typeInvoices)
    const nuevaURL = this.location.path() + '/invoices/' + this.typeInvoices;
    const index = nuevaURL.indexOf('/');
    this.location.go(
      nuevaURL.substring(0, index) + '/invoices/' + this.typeInvoices
    );
    this.invoiceData = null;
    this.showForm = false;
    this.document_buttons_chico = false;
  }

  refrescar() {
  //  this.fetchData();
  }

  refreshInvoiceData() {
    this.orderService
      .getFilterData(
        this.typeInvoices,
        this.pageNumber,
        this.pageSize,
        this.searchParams
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(this.localStorageService.getItem('per_page'));
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
          this.showCountFilter = data.meta.total;

          if (Object.keys(this.searchParams).length === 0) {
            this.showAlertFilter = false;
          } else {
            this.showAlertFilter = true;
          }
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  /******* EVENTO DEL FILTRO  *******/
  filterChanged(searchTerm: any) {
    this.alertService.loading('swal_messages.please_wait');
    this.searchParams = searchTerm;
    this.orderService
      .getFilterData(
        this.typeInvoices,
        this.pageNumber,
        this.pageSize,
        this.searchParams
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(this.localStorageService.getItem('per_page'));
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
          this.showCountFilter = data.meta.total;
          if (Object.keys(searchTerm).length === 0) {
            this.showAlertFilter = false;
          } else {
            this.showAlertFilter = true;
          }
          this.startAutoFetch();
          this.alertService.close();
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.localStorageService.setItem('per_page', String(event.pageSize));
    this.pageSize = event.pageSize;
    //this.fetchData();
  }
  getBadgeColor(stage: string, requiere_action: string, priority: string): string {

    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (!requiere_action) {
      return statusColorMap['false'];
    }

    if (requiere_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }

  getBadgeColorVehicle(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
    }
    return statusColorMap[status] || 'text-new';
  }

}
