<div *ngIf="!noDocumentsFound">
  <h3>Documentos del Paquete Financiero</h3>
  <div>
    <!-- Iterar sobre los paquetes de documentos -->
    <div *ngFor="let package of userPackDocuments; let i = index" class="documents-container">
      <!-- Aquí el botón para expandir/cerrar el contenido -->
      <div class="main-document">
        <h4 (click)="togglePackage(i)" style="cursor: pointer;">
          <i class="icon-folder"></i> {{ package.funder_package_name || 'Paquete sin nombre' }}
        </h4>
      </div>

      <!-- Mostrar los documentos solo si está expandido -->
      <div *ngIf="openedPackageIndex === i">
        <div *ngFor="let document of package.package_documents">
          📄 {{ document }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="drop-zone" *ngIf="noDocumentsFound">
  <p>No se encontraron documentos.</p>
</div>
