import { Routes } from '@angular/router';
import { OrdersComponent } from './pages/orders/orders.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { AuthGuard } from './core/guard/auth/auth.guard';
import { ClientsComponent } from './pages/clients/clients.component';
import { AutomationComponent } from './pages/automation/automation.component';
import { SettingComponent } from './pages/setting/setting.component';
import { TitleResolver } from './core/resolver/metatitles/metatitles.resolver';
import { NotFoundComponent } from './pages/component/not-found/not-found.component';
import { HelpComponent } from './pages/component/header/help/help.component';
import { RecoverPasswordComponent } from './pages/login/recover-password/recover-password.component';
import { ResetPasswordComponent } from './pages/login/reset-password/reset-password.component';
import { IconComponent } from './pages/icon/icon.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { invoicesRoutes } from './core/routes/invoices-routing.module';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'orders/contracts',
        pathMatch: 'full',
      },
      {
        path: 'icon',
        component: IconComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },
      {
        path: 'orders',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },

      {
        path: 'orders/all',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.all' },
      },
      {
        path: 'orders/cancelled',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.cancelled' },
      },
      {
        path: 'orders/delivered',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.delivered' },
      },
      {
        path: 'orders/drafts',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.delivered' },
      },
      {
        path: 'orders/drafts/:reference',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.delivered' },
      },
      {
        path: 'orders/all/:reference',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.all' },
      },
      {
        path: 'orders/contracts',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.contracts' },
      },
      {
        path: 'orders/after-sales',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.after-sales' },
      },
      {
        path: 'orders/cancelled/:reference',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.cancelled' },
      },
      {
        path: 'orders/delivered/:reference',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.delivered' },
      },
      {
        path: 'orders/contracts/add',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },
      {
        path: 'orders/contracts/:reference',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },
      {
        path: 'orders/contracts/:reference/:tab',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },
      {
        path: 'orders/after-sales/:reference',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },
      {
        path: 'orders/:reference',
        component: OrdersComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.orders' },
      },

      {
        path: 'clients',
        component: ClientsComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.clients' },
      },
      {
        path: 'clients/:reference',
        component: ClientsComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.clients' },
      },
      {
        path: 'clients/add',
        component: ClientsComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.clients' },
      },
      {
        path: 'settings',
        component: SettingComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.settings' },
      },
      {
        path: 'settings/automation',
        component: AutomationComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.automations' },
      },
      {
        path: 'settings/profile',
        component: ProfileComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.profile' },
      },
      {
        path: 'settings/automation/:reference',
        component: AutomationComponent,
        resolve: { title: TitleResolver },
        data: { title: 'titles.automations' },
      },
      ...invoicesRoutes,
      {
        path: 'help',
        component: HelpComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
