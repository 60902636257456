import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FinancialDataService {
  private apiUrlFd = environment.apiURL + 'financial-data';

  constructor(private http: HttpClient) {}

  getUserPackDocuments(reference: string): Observable<any> {
    const url = `https://api.qa.belender.net/users/d9a9d2b5-0139-42ef-81a0-e8a29e8bddd4/pack-documents`;
    return this.http.get(url);
  }

  getFinancialStatus(): Observable<any> {
    const url = `${this.apiUrlFd}/status`;
    return this.http.get(url);
  }

  getFinancialDataRequest(): Observable<any> {
    return this.http
      .get(
        `${this.apiUrlFd}/requests/6a618693-19a4-494e-b9c1-530dd75ba0e2/fast-json`
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => new Error('Error fetching financial data.'));
  }

  /*   requestClavePin(): Observable<any> {
    return this.http.post(
      `https://api.qa.belender.net/pre-request/clave-pin-sms`,
      {}
    );
  } */

  requestClavePin(): Observable<any> {
    return this.http
      .post(`${this.apiUrlFd}/pre-request/clave-pin-sms`, {})
      .pipe(catchError(this.handleError));
  }

  getFinancialData(): Observable<any> {
    return this.http
      .get(`${this.apiUrlFd}/requests/fast-json`)
      .pipe(catchError(this.handleError));
  }
}
