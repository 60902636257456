import { Component, Input } from '@angular/core';
import { FinancialDataService } from '../../../core/services/financialData/financial-data.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { AlertService } from '../../../core/services/alert/alert.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-financial-data',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './financial-data.component.html',
  styleUrl: './financial-data.component.css',
})
export class FinancialDataComponent {
  @Input() client_reference?: any;
  @Input() client?: any;
  @Input() activeForm?: any;
  @Input() clientData?: any;

  userPackDocuments: any[] = [];

  financialStatus: any = null;

  financialData: any;

  constructor(
    private financialDataService: FinancialDataService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    /*
    this.loadFinancialStatus();

    this.requestClavePin(); */

    /*     this.getFinancialData2(); */

    /*     this.route.paramMap.subscribe((params) => {
      const requestId = params.get('requestId');
      if (requestId) {
        this.getFinancialData(requestId);
        console.log(requestId);
      }
    }); */

    this.loadUserPackDocuments();
    this.getFinancialData();
  }

  noDocumentsFound = true;

  loadUserPackDocuments(): void {
    const userReference = this.localStorageService.getItem('user_reference');

    if (userReference) {
      this.financialDataService.getUserPackDocuments(userReference).subscribe({
        next: (data) => {
          if (data && data.data.length > 0) {
            // Verifica que data.data contenga los documentos
            this.userPackDocuments = data.data;
            this.noDocumentsFound = false;
          } else {
            this.noDocumentsFound = true;
          }
          console.log(
            'Documentos del paquete financiero:',
            this.userPackDocuments
          );
        },
        error: (err) => {
          console.error(
            'Error al obtener los documentos del paquete financiero:',
            err
          );
          this.noDocumentsFound = true;
        },
      });
    }
  }

  loadFinancialStatus(): void {
    this.financialDataService.getFinancialStatus().subscribe({
      next: (data) => {
        this.financialStatus = data;
        console.log('Estado financiero:', this.financialStatus);
      },
      error: (err) => {
        console.error('Error al obtener el estado financiero:', err);
        /*     this.alertService.error('Error al obtener el estado financiero'); */
      },
    });
  }

  /*   requestId: string = '12345'; */

  /*   getRequestId(): void {
    this.financialDataService.getRequestId().subscribe(
      (response) => {
        const requestId = response.request_id;
        console.log('Request ID obtenido:', requestId);

        this.getFinancialData(requestId);

        console.log('Request ID:', response.request_id);
        console.log('Widget URL:', response.widget_url);
      },
      (error) => {
        console.error('Error obteniendo el request ID', error);
      }
    );
  } */

  getFinancialData(): void {
    this.financialDataService.getFinancialDataRequest().subscribe({
      next: (data) => {
        this.financialData = data;
        console.log('Datos financieros:', this.financialData);
      },
      error: (error) => {
        console.error('Error obteniendo los datos financieros', error);
      },
    });
  }

  /*   requestClavePin() {
    this.financialDataService.requestClavePin().subscribe({
      next: (response) => {
        const requestId = response.request_id;
        console.log('Request ID obtenido:', requestId);

        this.getFinancialData();
      },
      error: (err) => {
        console.error('Error solicitando Clave PIN:', err);
      },
    });
  } */

  /*   getFinancialData2(): void {
    this.financialDataService.getFinancialData().subscribe({
      next: (data) => {
        this.financialData = data;
        console.log('Datos getFinancialData2:', this.financialData);
      },
      error: (error) => {
        console.error('Error obteniendo los datos financieros', error);
      },
    });
  } */

  /*   userPackDocuments: any[] = []; */

  openedPackageIndex: number | null = null;

  togglePackage(index: number): void {
    this.openedPackageIndex = this.openedPackageIndex === index ? null : index;
  }
}
