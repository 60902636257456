import { Component, Output, EventEmitter } from '@angular/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent {
  @Output() filteredClients = new EventEmitter<any[]>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() clearSearch = new EventEmitter<void>();

  searchParams: any = {};
  searchTerms = new Subject<string>();

  selectedFilters: Set<string> = new Set();
  showDropdown = false;
  showClear = false;
  clearable = false;
  optionsVisible = false;
  justSelected: boolean = false;

  selectedOption: string = '';
  selectedValue: string = '';
  displayText: string = '';

  filterValues: {
    search: string;
    type: string | null;
  } = {
    search: '',
    type: null,
  };

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  isLoading: boolean = false;

  ngOnInit() {
    this.checkFilters();
  }

  ngAfterViewInit() {
    this.searchTerms
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.search();
      });
  }

  checkFilters(): void {
    this.searchParams = JSON.parse(
      this.localStorageService.getItem('searchParamsClients') || '{}'
    );
    if (this.searchParams) {
      this.preCargarFiltros();
    }
  }

  preCargarFiltros(): void {
    if (this.searchParams['search']) {
      this.filterValues.search = this.searchParams['search'];
      this.searchTerms.next(this.filterValues.search);
    } else {
      this.searchTerms.next('');
    }

    this.filterValues.type = this.searchParams['type'] ?? null;
    this.selectedValue = this.filterValues.type ?? '';

    switch (this.filterValues.type) {
      case 'organization':
        this.displayText = 'Empresa';
        break;
      case 'individual':
        this.displayText = 'Particular';
        break;
      case 'self-employed':
        this.displayText = 'Autónomo';
        break;
      default:
        this.displayText = '';
    }

    this.clearable = !!this.filterValues.type;
    this.selectedFilters[this.filterValues.type ? 'add' : 'delete']('type');
    this.updateButtonLabel();
    this.search();
  }

  search(): void {
    this.searchParams = {};
    if (this.filterValues.search) {
      this.searchParams['search'] = `${this.filterValues.search}`;
    }

    if (this.filterValues.type) {
      this.searchParams['type'] = `${this.filterValues.type}`;
    }

    this.localStorageService.setItem(
      'searchParamsClients',
      JSON.stringify(this.searchParams)
    );

    /*  this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.searchParams,
      queryParamsHandling: 'merge',
    }); */

    this.emitEvent();
    this.showClear = true;
  }

  keyup(event: KeyboardEvent) {
    this.filterValues.search = (event.target as HTMLInputElement).value;

    if (this.filterValues.search) {
      this.selectedFilters.add('search');
      this.showClear = true;
    } else {
      this.selectedFilters.delete('search');
      this.removeQueryParam('search');
    }

    this.updateButtonLabel();
    this.searchTerms.next(this.filterValues.search);
  }

  clearSelectedOption() {
    this.selectedValue = '';
    this.displayText = '';
    this.filterValues.type = null;
    this.selectedFilters.delete('type');
    this.clearable = false;
    this.updateButtonLabel();

    this.removeQueryParam('type');

    this.showClear = this.selectedFilters.size > 0;
  }

  updateButtonLabel() {
    const filterCount = Array.from(this.selectedFilters).filter(
      (filter) => filter !== 'search'
    ).length;

    this.showClear = filterCount > 0;
  }

  get filterButtonLabel() {
    const filterCount = Array.from(this.selectedFilters).filter(
      (filter) => filter !== 'search'
    ).length;

    return filterCount > 0 ? `Filtrar (${filterCount})` : 'Filtrar';
  }

  onFocus(): void {
    this.optionsVisible = true;
  }

  hideOptions() {
    setTimeout(() => {
      this.optionsVisible = false;
    }, 200);
  }

  selectOption(type: any, text: string, inputElement: HTMLInputElement): void {
    this.selectedValue = text;
    this.displayText = text;
    this.optionsVisible = false;
    this.filterValues.type = type;
    this.clearable = true;
    this.selectedFilters.add('type');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  clearFilter(event: Event): void {
    event.stopPropagation();

    this.filterValues = {
      search: '',
      type: null,
    };

    this.searchTerms.next('');
    this.searchParams = {};

    this.localStorageService.removeItem('searchParamsClients');

    this.selectedFilters.clear();
    this.selectedValue = '';
    this.displayText = '';
    this.clearable = false;
    this.showClear = false;
    this.showDropdown = false;

    /*  this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: '',
    }); */

    this.emitEvent();
  }

  hasActiveFilters(): boolean {
    return Object.keys(this.searchParams).length > 0;
  }

  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }

  closeTimeout: any;

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 4000); //  1 seconds
  }

  startCloseTimeout(): void {
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 500); //  1 seconds
  }

  cancelCloseTimeout(): void {
    this.showDropdown = true;
    clearTimeout(this.closeTimeout);
  }

  removeQueryParam(param: string): void {
    this.searchParams = { ...this.searchParams };
    delete this.searchParams[param];

    this.localStorageService.setItem(
      'searchParamsClients',
      JSON.stringify(this.searchParams)
    );

    /*   this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.searchParams,
      queryParamsHandling: '',
    }); */

    this.emitEvent();
  }
}
