import { Component, inject ,HostListener  } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, Location } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    RouterLink,TranslateModule
  ]
})
export class NavigationComponent {
  private breakpointObserver = inject(BreakpointObserver);
  submenuOrders = false;
  submenuInvoices = false;
  account_name:any;
  submenuOpen: boolean = false;
  submenuOpenConf: boolean = false;
  submenuPosition = { top: '0px', left: '0px' };
  closeTimeout: any;
  closeTimeoutConf: any;
  name:any = 'Negocio';
  type:any = 'broker';
  role:any = 'admin';

  constructor(private localStorageService:LocalStorageService,private location: Location,) {
    this.name = this.localStorageService.getItem('name');
    this.type = this.localStorageService.getItem('account_type');
    this.account_name = this.localStorageService.getItem('account_name');
    this.role = this.localStorageService.getItem('role');

    const nuevaURL = this.location.path();
    if (nuevaURL.includes('orders/contracts')) {
      this.submenuOrders = !this.submenuOrders;
    }
  }


  toggleSubmenuOrders(): void{
    this.submenuOrders = !this.submenuOrders;
  }
  closeSubmenuOrders(): void{
    this.submenuOrders = false;
  }


  toggleSubmenuInvoices(): void{
    this.submenuInvoices = !this.submenuInvoices;
  }
  closeSubmenuInvoices(): void{
    this.submenuInvoices = false;
  }


  toggleSubmenu(event: MouseEvent): void {
    this.submenuOpen = !this.submenuOpen;
    if (this.submenuOpen) {
      const target = (event.target as HTMLElement).closest('.has-submenu') as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.submenuPosition = {
        top: `${rect.top}px`,
        left: `${rect.right}px`
      };
    }
    event.stopPropagation();
  }

  startCloseTimeout(): void {

    this.closeTimeout = setTimeout(() => {
      this.submenuOpen = false;
    }, 3000); // 3 seconds
  }

  cancelCloseTimeout(): void {
    this.submenuOpen = true;
    clearTimeout(this.closeTimeout);
  }
  toggleSubmenuConf(event: MouseEvent): void {
    this.submenuOpenConf = !this.submenuOpenConf;
    if (this.submenuOpenConf) {
      const target = (event.target as HTMLElement).closest('.has-submenu') as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.submenuPosition = {
        top: `${rect.top}px`,
        left: `${rect.right}px`
      };
    }
    event.stopPropagation();
  }
  startCloseTimeoutConf(): void {
    this.closeTimeoutConf = setTimeout(() => {
      this.submenuOpenConf = false;
    }, 3000); // 3 seconds
  }

  cancelCloseTimeoutConf(): void {
    this.submenuOpenConf = true;
    clearTimeout(this.closeTimeoutConf);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (this.submenuOpen) {
      this.submenuOpen = false;
    }
  }
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
}
