import {
  Component,
  Output,
  Input,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common'; // Asegúrate de importar CommonModule
import { TranslateModule } from '@ngx-translate/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { TimelineComponent } from '../../timeline/timeline.component';
import { AutomationService } from '../../../core/services/automation/automation.service';
import { AlertService } from '../../../core/services/alert/alert.service';
@Component({
  selector: 'app-form-add-automation',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    TimelineComponent,
  ],
  templateUrl: './form-add-automation.component.html',
  styleUrl: './form-add-automation.component.css',
})
export class FormAddAutomationComponent {
  @ViewChild('requireActionCheckbox') requireActionCheckbox!: ElementRef;
  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() addAutomation = new EventEmitter<boolean>();
  @Input() automationData?: any;
  form: FormGroup;
  arrayConditions: any = [{ condition: '', operator: '', value: '' }];
  arrayActions: any = [{ action: '', value: '' }];
  enabled= false;
  opt = 'new';
  events: any[] = [];
  actions: any[] = [];
  conditions: any[] = [];
  btnEnable=false;
  constructor(
    private fb: FormBuilder,
    private automationService: AutomationService,
    private alertService: AlertService,
    private location: Location
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      event_id: ['', Validators.required],
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['automationData']) {
      if (this.automationData) {
        this.btnEnable = true;
        this.opt = 'update';
       this.form.patchValue({
          name: this.automationData.name,
          description: this.automationData.description,
          event_id: this.automationData.events,

        });
        if (this.automationData.enabled) {
          this.enabled = true;
          this.requireActionCheckbox.nativeElement.checked = true;
        }
        this.arrayConditions = this.automationData.conditions;
        this.arrayActions = this.automationData.actions;
      } else {
      }
    }
  }

  ngAfterViewInit() {
    this.fetchData();
    this.fetchData2();
    this.fetchData3();
  }

  fetchData(): void {
    this.automationService.getEvent().subscribe({
      next: (data) => {
        this.events = data.data;

      },
      error: (error) => console.error('There was an error!', error),
    });
  }
  fetchData2(): void {
    this.automationService.getCondition().subscribe({
      next: (data) => {
        this.conditions = data.data;

      },
      error: (error) => console.error('There was an error!', error),
    });
  }
  fetchData3(): void {
    this.automationService.getActions().subscribe({
      next: (data) => {
        this.actions = data.data;
      },

      error: (error) => console.error('There was an error!', error),
    });
  }

  addCondition() {
    this.arrayConditions.push({ condition: '', operator: '', value: '' });
  }

  removeCondition(index: number) {
    this.arrayConditions.splice(index, 1);
  }

  updateCondition(index: number, field: string, event: any) {
    this.arrayConditions[index][field] = event.target.value;
  }

  addAction() {
    this.arrayActions.push({ action: '', value: '' });
  }

  removeAction(index: number) {
    this.arrayActions.splice(index, 1);
  }

  updateAction(index: number, field: string, event: any) {
    this.arrayActions[index][field] = event.target.value;
  }

  emitBackButtonClick() {
    const nuevaURL = this.location.path();
    const index = nuevaURL.indexOf('/');
    this.location.go(nuevaURL.substring(0, index) + '/settings/automation');
    this.form.patchValue({
      name: '',
      description: '',
      event_id: '',
    });

    this.arrayConditions = [{ condition: '', operator: '', value: '' }];
    this.arrayActions = [{ action: '', value: '' }];
    this.backButtonClick.emit(true);
  }

  onCheckboxChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }

  emitAddAuto() {
    this.opt = 'new';
    this.form.patchValue({
      name: '',
      description: '',
      event_id: '',
    });

    this.arrayConditions = [{ condition: '', operator: '', value: '' }];
    this.arrayActions = [{ action: '', value: '' }];
    this.addAutomation.emit(true);
  }

  onSubmit() {
    let formResult = this.form.value;
    formResult.events = formResult.event_id;
    formResult.conditions = this.arrayConditions;
    formResult.actions = this.arrayActions;
    formResult.enabled = this.enabled;
    this.alertService.loading('swal_messages.adding_automation');
    this.automationService.addData(formResult).subscribe({
      next: (data) => {
        this.alertService
          .successOk('swal_messages.automation_added')
          .then((result) => {
            if (result.isConfirmed) {
              this.emitAddAuto();
            }
          });
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      },
    });
  }
  //onSubmitOrder(): void {};
   onSubmitOrder(): void {
    let formResult = this.form.value;
    formResult.events = formResult.event_id;
    formResult.conditions = this.arrayConditions;
    formResult.actions = this.arrayActions;
    formResult.enabled = this.enabled;
    this.alertService.loading('swal_messages.updating_automation');
    this.automationService
      .updateData(this.automationData.reference, formResult)
      .subscribe({
        next: (result:any) => {
          this.alertService
            .successOk('swal_messages.automation_updated')
            .then((result) => {
              if (result.isConfirmed) {
                this.form.reset();
                this.emitAddAuto();
                this.emitBackButtonClick();
              }
            });
        },
        error: (error:any) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
            }
          });
        },
      });
  }
}
