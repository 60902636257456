import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { SelectCountryPrefixComponent } from '../../component/select-country-prefix/select-country-prefix.component';
import { CustomDateAdapter } from './custom-adapter';
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function phoneNumberValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const phonePrefix = formGroup.get('phonePrefix')?.value;
    const phoneNumber = formGroup.get('phoneNumber')?.value;

    if (phonePrefix === '+34' && (!phoneNumber || !/^[0-9]{9}$/.test(phoneNumber))) {
      return { invalidPhoneNumber: true }; // Devuelve un error si no tiene 9 dígitos
    }

    return null; // Retorna nulo si todo está bien
  };
}

@Component({
  selector: 'app-form-add-client',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    SelectCountryPrefixComponent,
  ],

  templateUrl: './form-add-client.component.html',
  styleUrl: './form-add-client.component.css',
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class FormAddClientComponent {
  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() addClient = new EventEmitter<boolean>();

  form: FormGroup;

  clients: Array<{
    name: string;
    reference: string;
    tax_id: string;
    lastname: string;
  }> = [];
  filteredClients: Array<{ name: string; reference: string; tax_id: string }> =
    [];

  search$ = new Subject<string>();

  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private clientService: ClientsService
  ) {
    this.form = this.fb.group({
      status: [true],
      type: ['', Validators.required],
      tax_id: ['', Validators.required],
      assignee_id: [''],
      name: ['', Validators.required],
      lastname: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      billing_address: [''],
      billing_postal_code: ['', [Validators.pattern('^[0-9]{4,5}$')]],
      billing_city: [''],
      billing_state: [''],
      phonePrefix: ['+34'],
      birth_date: [null],
      phoneNumber: ['', Validators.required],
    }, { validators: phoneNumberValidator() }); // Aplicar la validación personalizada al grupo
  }

  ngOnInit(): void {
    this.loadIndividualClients();
    this.search$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((term) => {
        this.fetchClient(term);
      });

    this.form.get('type')?.valueChanges.subscribe((typeValue) => {
      if (typeValue === 'organization') {
        this.form.get('lastname')?.reset();
      }
    });
  }

  isFieldInvalid(field: string): any {

    const control = this.form.get(field);
    return control?.invalid && control?.touched;
  }


getFormError(field: string, errorType: string): boolean {
  const control = this.form.get(field);
  return control?.errors?.[errorType];
}

  fetchClient(term: string): void {
    this.clientService.searchClients(term).subscribe({
      next: (response) => {
        this.alertService.close();
        if (response.success) {
          const clientData: any[] = response.data;
          this.clients = clientData.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });
  }

  emitAddClient() {
    this.addClient.emit(true);
  }

  onSubmit() {


    this.alertService.loading('swal_messages.creating_client');

    let formData = { ...this.form.value };

    const birthDate = this.form.get('birth_date')?.value;

    if (birthDate) {
      const formattedDate = this.convertToLocalDate(new Date(birthDate));
      console.log('formattedDate', formattedDate);

      formData.birth_date = formattedDate;
    }

    let aux = this.form.get('phoneNumber')?.value;
    if (aux) {
      let phonePrefix = this.form.get('phonePrefix')?.value;
      if (!phonePrefix) {
        phonePrefix = '+34';
      }
      const phoneNumber = this.form.get('phoneNumber')?.value;
      const combinedPhoneNumber = `(${phonePrefix})${phoneNumber}`;

      formData.phone = combinedPhoneNumber;
    }

    this.clientService.addData(formData).subscribe(
      (response) => {
        this.alertService.successOk('swal_messages.client_created');
        this.emitAddClient();
        this.loadIndividualClients();
        this.emitBackButtonClick();
        this.resetForm();
      },
      (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
  }

  resetForm(): void {
    this.form.reset({
      status: true,
      type: '',
      tax_id: '',
      assignee_id: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      billing_address: '',
      billing_postal_code: '',
      billing_city: '',
      billing_state: '',
      phoneNumber: '',
      phonePrefix: '+34',
    });
    this.changePrefix('+34');
  }

  changePrefix(event: any) {
    this.form.get('phonePrefix')?.setValue(event);
  }

  emitBackButtonClick() {
    this.resetForm();
    this.backButtonClick.emit(true);
  }

  getSelectedClientType(): string | null {
    return this.form.get('type')?.value || null;
  }

  get phone() {
    return this.form.get('phone');
  }

  nameValue: string | null = null;
  lastnameValue: string | null = null;
  individualClients: any[] = [];
  selectedClientType: string | null = null;

  loadIndividualClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        this.alertService.close();
        if (response.success) {
          const clientData: any[] = response.data;
          this.clients = clientData.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });
  }

  searchClients(term: string): Observable<
    Array<{
      name: string;
      reference: string;
      tax_id: string;
      lastname?: string;
    }>
  > {
    return new Observable((observer) => {
      term = term || '';
      if (!term.trim()) {
        observer.next([]);
      } else {
        const filtered = this.clients.filter((client) => {
          const nameMatches = client.name
            ? client.name.toLowerCase().includes(term.toLowerCase())
            : false;
          const taxIdMatches = client.tax_id
            ? client.tax_id.toLowerCase().includes(term.toLowerCase())
            : false;
          const fullnameMatches = client.lastname
            ? `${client.name} ${client.lastname}`
                .toLowerCase()
                .includes(term.toLowerCase())
            : false;
          return nameMatches || taxIdMatches || fullnameMatches;
        });
        observer.next(filtered);
      }
    });
  }

  onSearch(event: any): void {
    const term = event.term || '';
    this.search$.next(term);
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    if (selectedDate) {
      const adjustedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      this.form.get('birth_date')?.setValue(adjustedDate);
    }
  }

  convertToLocalDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }
}
