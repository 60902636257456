import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private apiUrl = environment.apiURL + 'orders'; // URL del API
  private apiUrlNotes = environment.apiURL + 'order-notes'; // URL del API
  private apiUrlLogs = environment.apiURL + 'order-logs'; // URL del API
  private apiUrlAcc = environment.apiURL + 'accounts';
  private apiUrlDelivery = environment.apiURL + 'delivery-data';

  constructor(private http: HttpClient) { }

  /*   getData(type: string = 'contracts', pageNumber: number = 0, pageSize: number = 0): Observable<any> {
    if (pageNumber > 0 && pageSize > 0) {
      return this.http.get(`${this.apiUrl}/${type}/?page=${pageNumber}&pageSize=${pageSize}`);
    }
    return this.http.get(`${this.apiUrl}/${type}`);
  } */
  getData(
    type: string = 'contracts',
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {}
  ): Observable<any> {
    var url = `${this.apiUrl}/stages/${type}`;
    if (type == '') {
      url = `${this.apiUrl}`;
    }

    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        ...params,
      },
    });
  }
  getFilterData(
    type: string = 'contracts',
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {}
  ): Observable<any> {
    var url = `${this.apiUrl}/stages/${type}`;
    if (type == '') {
      url = `${this.apiUrl}`;
    }

    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        ...params,
      },
    });
  }
  getDataByReference(
    reference: string = '',

  ): Observable<any> {
    const url = `${this.apiUrl}/${reference}`;
    return this.http.get(url);
  }

  getStages(): Observable<any> {
    return this.http.get(`${this.apiUrl}/stages`);
  }
  getTimeline(item: any): Observable<any> {
    return this.http.get(this.apiUrlLogs + '/timeline/' + item);
  }
  // Método para añadir datos a la API
  addData(item: any): Observable<any> {
    return this.http.post(this.apiUrl + '/new', item);
  }

  addNotes(item: any): Observable<any> {
    return this.http.post(this.apiUrlNotes + '/new', item);
  }

  getNotes(item: any): Observable<any> {
    return this.http.get(this.apiUrlNotes + '/order/' + item);
  }

  getDeliveryData(): Observable<any> {
    return this.http.get(this.apiUrlDelivery);
  }
  // Método para añadir datos a la API
  searchOrder(reference: string): Observable<any> {
    return this.http.get(this.apiUrl + '/' + reference);
  }

  // Método para añadir datos a la API
  updateData(reference: string, body: any): Observable<any> {
    return this.http.put(this.apiUrl + '/' + reference + '/update', body);
  }

  updateStatus(reference: string, body: any): Observable<any> {
    return this.http.put(this.apiUrl + '/' + reference + '/update-stage', body);
  }

  //filtered orders
  getOrders(params: any = {}): Observable<any> {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get<any>(`${this.apiUrl}`, {
      params: httpParams,
    });
  }

  searchOrders(search: string): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}?search=${search}`);
  }

  searchOrdersByFilter(filters: { [key: string]: string }): Observable<any> {
    let url = `${this.apiUrl}`;
    const params = [];

    for (const key in filters) {
      if (filters.hasOwnProperty(key) && filters[key]) {
        params.push(`${key}=${filters[key]}`);
      }
    }

    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    return this.http.get<any>(url);
  }

  getAccounts(): Observable<any> {
    return this.http.get<any>(`${this.apiUrlAcc}`);
  }

  filterOrdersByBrand(brand: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}?vehicle_brand=${brand}`);
  }
}
